import React from 'react'
import Link from 'gatsby-link'
import anime from 'animejs/lib/anime.es.js';
import {navigate} from 'gatsby';
import './menu.scss'

import arrowDownPath from '../../../images/icons/arrow-down.svg';
import arrowUpPath from '../../../images/icons/arrow-up.svg';

export default class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

    }

    toggleMenu = () => {
        const animElements = anime.timeline();
        if (this.state.visible) {
            animElements
                .add({
                    targets: '.menu-mobile-item',
                    opacity: [1, 0],
                    delay: function (el, i, l) { return i * 10; },
                    easing: 'easeInSine'
                })
                .add({
                    targets: '.menu-mobile',
                    opacity: [1, 0],
                    duration: 250,
                    easing: 'linear',
                    complete: (anim) => {
                        this.setState({ visible: !this.state.visible });
                    }
                });
        } else {
            this.setState(
                { visible: !this.state.visible },
                () => {
                    animElements
                        .add({
                            targets: '.menu-mobile',
                            opacity: [0, 1],
                            delay: 50,
                            duration: 250,
                            easing: 'linear'
                        })
                        .add({
                            targets: '.menu-mobile-item',
                            opacity: [0, 1],
                            delay: function (el, i, l) { return i * 10; },
                            easing: 'easeInSine'
                        });
                }
            );
        }
    }

    onClickSameLocation = (toUrl) => {
        if(window.location.pathname === toUrl)
            this.toggleMenu();
        else 
            navigate(toUrl);
    }

    render() {
        return (
            <div className="menu-container">
                <button onClick={this.toggleMenu}>
                    Menu
                <img src={this.state.visible ? arrowUpPath : arrowDownPath} alt="icon"/>
                </button>
                {this.state.visible &&
                    <ul className="menu-mobile">
                        <li className="menu-mobile-item">
                            <Link to="/" onClick={ _ => this.onClickSameLocation("/")}>Lo que Ofrecemos</Link>
                        </li>
                        <li className='menu-mobile-item'>
                            <Link to="/contacto" onClick={ _ => this.onClickSameLocation("/contacto")}>Contactanos</Link>
                        </li>
                        <li className='menu-mobile-item'>
                            <Link to="/blog" onClick={ _ => this.onClickSameLocation("/blog")}>Blog</Link>
                        </li>
                    </ul>
                }
                <ul className="menu">
                    <li className="menu-item">
                        <Link to="/">Lo que Ofrecemos</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to="/contacto">Contactanos</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to="/blog">Blog</Link>
                    </li>
                </ul>
            </div>
        );
    }
}
