import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Menu from '../menu/menu'
import logoPath from '../../../images/sd-logo-light.svg'


import "./header.scss"

const Header = ({ siteTitle }) => (<>

	<header className="header">

		<div className="logo-container">
			<Link to="/">
				<picture>
					<img src={logoPath} alt="spheredevs logo" />
				</picture>
			</Link>
		</div>

		<Menu />

	</header>


</>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
