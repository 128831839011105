import React from 'react'
import PropTypes from 'prop-types'

import "./icon-with-link.scss"

function IconWithLink(props) {
    return (
        <a className="icon-with-link" href={props.urlLink} target="_blank">
            <picture className="icon-with-link-icon-container">
                <img className="icon-with-link-icon" src={props.iconSrc} alt="icon"/>
            </picture>
            <p className="icon-with-link-label">{props.linkLabel}</p>
        </a>
    )
}

IconWithLink.propTypes = {
    linkLabel: PropTypes.string,
    urlLink: PropTypes.string,
    iconSrc: PropTypes.string,
}

IconWithLink.defaulProps = {
    linkLabel: ``,
    urlLink: ``,
    iconSrc: ``
}

export default IconWithLink

