/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./shared/header/header"
import Footer from "./shared/footer/footer"

import "./layout.scss"

import whatsappIconSrc from '../images/icons/whatsapp-icon.svg'
import mailtoIconSrc from '../images/icons/mailto-icon.svg'

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
		site {
			siteMetadata {
				title
			}
		}
    }
	`)

	const featureLinks = [
		{ id: "home", link: "/", label: "Inicio" },
		{ id: "services", link: "/", label: "Lo que Ofrecemos" },
		{ id: "contact", link: "/contacto", label: "Contacto" }
	];

	const contactInfoLinks = [
		{
			id: "whatsapp",
			link: "https://api.whatsapp.com/send?phone=50683609763",
			label: "+506 8360 9763",
			iconSrc: whatsappIconSrc
		},
		{
			id: "mailto",
			link: "mailto:info@spheredevs.com?subject=Consulta",
			label: "info@spheredevs.com",
			iconSrc: mailtoIconSrc
		}
	];

	return (
		<>
			<div
				className="container"
			>
				<Header siteTitle={data.site.siteMetadata.title} />
				<main className="content">{children}</main>
				<Footer
					featureLinksArray={featureLinks}
					contactInfoLabel="Contactenos:"
					contactInfoLinksArray={contactInfoLinks}
					socialMediaLabel="Síguenos en:"
					facebookLink="https://www.facebook.com/spheredevs" 
					twitterLink="https://twitter.com/SphereDevs"
					facebookLinkLabel="Facebook" 
					twitterLinkLabel="Twitter"
					copyrightDesc="SphereDevs. Todos los derechos reservados."
				/>
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
