import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import IconWithLink from '../icon-with-link/icon-with-link'

import "./footer.scss"

import facebookIconSrc from '../../../images/icons/facebook-icon.svg'
import twitterIconSrc from '../../../images/icons/twitter-icon.svg'

const Footer = props => {
    return (
        <>
            <footer className="footer">

                <div className="footer-feature-links">
                    <ul className="footer-feature-links-list">
                        {
                            props.featureLinksArray.map((item) =>
                                <li key={item.id} className="footer-feature-links-item">
                                    <Link to={item.link}>{item.label}</Link>
                                </li>
                            )
                        }
                    </ul>
                </div>

                <div className="footer-contact-info">
                    <h3 className="footer-contact-info-title">{props.contactInfoLabel}</h3>
                    <div className="footer-contact-info-list">
                        {
                            props.contactInfoLinksArray.map((item) =>
                                <IconWithLink
                                    key={item.id}
                                    linkLabel={item.label}
                                    urlLink={item.link}
                                    iconSrc={item.iconSrc} />)
                        }
                    </div>
                </div>

                <div className="footer-social-media">
                    <h3 className="footer-social-media-title">{props.socialMediaLabel}</h3>
                    <div className="footer-social-media-list">

                        <IconWithLink linkLabel={props.facebookLinkLabel} urlLink={props.facebookLink} iconSrc={facebookIconSrc} />
                        <IconWithLink linkLabel={props.twitterLinkLabel} urlLink={props.twitterLink} iconSrc={twitterIconSrc} />
                    </div>
                </div>

                <p className="footer-copyright">© {new Date().getFullYear()} {props.copyrightDesc}</p>

            </footer>

        </>
    )
}

Footer.propTypes = {
    featureLinksArray: PropTypes.array,
    contactInfoLabel: PropTypes.string,
    contactInfoLinksArray: PropTypes.array,
    socialMediaLabel: PropTypes.string,
    facebookLink: PropTypes.string,
    twitterLink: PropTypes.string,
    facebookLinkLabel: PropTypes.string,
    twitterLinkLabel: PropTypes.string,
    copyrightDesc: PropTypes.string,
}

Footer.defaultProps = {
    featureLinksArray: [],
    contactInfoLabel: ``,
    contactInfoLinksArray: [],
    socialMediaLabel: ``,
    facebookLink: ``,
    twitterLink: ``,
    facebookLinkLabel: ``,
    twitterLinkLabel: ``,
    copyrightDesc: ``,
}

export default Footer
